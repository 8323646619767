<template>
  <div>
    <Charts />
  </div>
</template>

<script>
import Charts from "./Charts";
export default {
  components: {
    Charts
  }
};
</script>

<style></style>
