<template>
  <div class="q-pa-xs col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
    <q-card style="max-height: 32vh;">
      <q-card-section class="row justify-center q-pa-lg">
        <div class="col-11 text-center text-h6 text-bold">Patrimônio Atual</div>
        <div class="col-11 text-center text-h6 text-bold text-blue">
          {{ formatString(totalEquity, "currency", 2) }}
        </div>
        <div class="col-grown text-h6 text-bold">Rentabilidade Geral</div>
        <div class="col-12 row justify-between">
          <span
            class="text-h6"
            :class="{
              'text-red': profitability < 0,
              'text-green': profitability > 0
            }"
          >
            {{ formatString(profitability, "number", 2, "%") }}
          </span>
          <span
            class="text-h6"
            :class="{
              'text-red': totalProfitOrLossValue < 0,
              'text-green': totalProfitOrLossValue > 0
            }"
          >
            {{ formatString(totalProfitOrLossValue, "currency", 2) }}
          </span>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import formatString from "@/helpers/formatString";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Tickers", ["tickers"]),
    totalEquity() {
      return this.tickers.reduce(
        (acc, ticker) => (acc += ticker.currentEquity),
        0
      );
    },
    totalProfitOrLossValue() {
      return this.tickers.reduce(
        (acc, ticker) => (acc += ticker.profitOrLossValue),
        0
      );
    },
    totalAmountPurchased() {
      return this.tickers.reduce(
        (acc, ticker) => (acc += ticker.totalAmountPurchased),
        0
      );
    },
    profitability() {
      return (this.totalProfitOrLossValue / this.totalAmountPurchased) * 100;
    }
  },
  methods: {
    formatString
  }
};
</script>

<style></style>
