<template>
  <div class="q-pa-xs col-12">
    <q-card>
      <q-card-section>
        <GChart type="BubbleChart" :data="tickersTypes" :options="options" />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { round } from "lodash";
export default {
  data() {
    return {
      options: {
        title: "Ativos",
        legend: "none",
        hAxis: { title: "% Dif. Part. Atual X Meta", minValue: 0 },
        vAxis: { title: "% Rentabilidade" },
        bubble: { textStyle: { fontSize: 11 } },
        explorer: {
          maxZoomOut: 2,
          keepInBounds: true
        },
        height: 800
      }
    };
  },
  computed: {
    ...mapGetters("Tickers", ["tickers"]),
    totalEquity() {
      return this.tickers.reduce(
        (acc, ticker) => (acc += ticker.currentEquity),
        0
      );
    },
    totalTickerTypeWeight() {
      return this.tickers
        .reduce((acc, ticker) => {
          const position = acc.findIndex(
            item => item.tickerTypeTitle === ticker.tickerTypeTitle
          );
          if (position < 0) {
            acc.push({
              tickerTypeTitle: ticker.tickerTypeTitle,
              tickerTypeWeight: ticker.tickerTypeWeight
            });
          }
          return acc;
        }, [])
        .reduce((acc, ticker) => (acc += ticker.tickerTypeWeight), 0);
    },
    tickersTypes() {
      let tickersTypes = [];
      tickersTypes.push([
        "ID",
        "% Dif.",
        "% Rentabilidade",
        "Ativo",
        "Vlr. Patrimônio"
      ]);
      this.tickers.forEach(ticker => {
        tickersTypes.push([
          ticker.ticker,
          round(ticker.differencePercentage, 2),
          round(ticker.profitOrLossPercentage, 2),
          ticker.ticker,
          round(ticker.currentEquity, 2)
        ]);
      });
      return tickersTypes;
    }
  },
  mounted() {}
};
</script>

<style></style>
