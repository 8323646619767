<template>
  <div class="q-pa-xs col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
    <q-card style="max-height: 32vh;">
      <q-card-section>
        <GChart type="ColumnChart" :data="tickersTypes" :options="options" />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      options: {
        title: "Grupo de ativos - Distribuição Atual X Meta",
        titleTextStyle: {
          fontSize: 14,
          bold: true
        },
        chartArea: {
          left: 40,
          width: "65%"
        }
      }
    };
  },
  computed: {
    ...mapGetters("Tickers", ["tickers"]),
    totalEquity() {
      return this.tickers.reduce(
        (acc, ticker) => (acc += ticker.currentEquity),
        0
      );
    },
    totalTickerTypeWeight() {
      return this.tickers
        .reduce((acc, ticker) => {
          const position = acc.findIndex(
            item => item.tickerTypeTitle === ticker.tickerTypeTitle
          );
          if (position < 0) {
            acc.push({
              tickerTypeTitle: ticker.tickerTypeTitle,
              tickerTypeWeight: ticker.tickerTypeWeight
            });
          }
          return acc;
        }, [])
        .reduce((acc, ticker) => (acc += ticker.tickerTypeWeight), 0);
    },
    tickersTypes() {
      let tickersTypes = [];
      tickersTypes.push(["Grupo de Ativo", "Atual", "Meta"]);
      this.tickers.reduce((acc, ticker) => {
        const position = acc.findIndex(
          item => item.tickerTypeTitle === ticker.tickerTypeTitle
        );
        if (position > 0) {
          acc[position].currentEquity += ticker.currentEquity;
        } else {
          acc.push({
            tickerTypeTitle: ticker.tickerTypeTitle,
            tickerTypeWeight: ticker.tickerTypeWeight,
            currentEquity: ticker.currentEquity
          });
        }
        return acc;
      }, tickersTypes);
      tickersTypes = tickersTypes.map((item, index) => {
        if (index > 0) {
          return [
            item.tickerTypeTitle,
            (item.currentEquity / this.totalEquity) * 100,
            (item.tickerTypeWeight / this.totalTickerTypeWeight) * 100
          ];
        } else {
          return item;
        }
      });
      return tickersTypes;
    }
  },
  mounted() {}
};
</script>

<style></style>
