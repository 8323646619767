<template>
  <div class="q-pa-xs col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
    <q-card style="max-height: 32vh;">
      <q-card-section>
        <GChart type="PieChart" :data="tickersTypes" :options="options" />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      options: {
        title: "Distribuição por Grupo de Ativos",
        titleTextStyle: {
          fontSize: 18,
          bold: true
        },
        is3D: true,
        chartArea: {
          top: 40,
          width: "100%"
        },
        legend: {
          position: "left"
        },
        height: "100%"
      }
    };
  },
  computed: {
    ...mapGetters("Tickers", ["tickers"]),
    tickersTypes() {
      let tickersTypes = [];
      tickersTypes.push(["Grupo de Ativo", "% Distribuição"]);
      this.tickers.reduce((acc, ticker) => {
        const position = acc.findIndex(
          item => item.tickerTypeTitle === ticker.tickerTypeTitle
        );
        if (position > 0) {
          acc[position].currentEquity += ticker.currentEquity;
        } else {
          acc.push({
            tickerTypeTitle: ticker.tickerTypeTitle,
            currentEquity: ticker.currentEquity
          });
        }
        return acc;
      }, tickersTypes);
      tickersTypes = tickersTypes.map((item, index) => {
        if (index > 0) {
          return [item.tickerTypeTitle, item.currentEquity];
        } else {
          return item;
        }
      });
      return tickersTypes;
    }
  },
  mounted() {}
};
</script>

<style></style>
