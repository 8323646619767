<template>
  <div class="q-ma-md row justify-evenly items-center">
    <GaugeProfitability />
    <PieDistributionByTicherType />
    <ColumnCurrentTargetDistribution />
    <BubbleTickers />
  </div>
</template>

<script>
import GaugeProfitability from "./GaugeProfitability";
import BubbleTickers from "./BubbleTickers";
import PieDistributionByTicherType from "./PieDistributionByTicherType";
import ColumnCurrentTargetDistribution from "./ColumnCurrentTargetDistribution";
export default {
  components: {
    GaugeProfitability,
    BubbleTickers,
    PieDistributionByTicherType,
    ColumnCurrentTargetDistribution
  }
};
</script>

<style></style>
